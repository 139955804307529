$(function() {

/*------------------------------------*\
    LOADING SPINNER
\*------------------------------------*/

$(window).on('load', function() {
    // Prevent SVG FOUC
    $('svg').fadeIn('1000');

    $('html').addClass('prevent-scroll');

    // DELAY REVEAL
    setTimeout(function() {
        $('.loader-wrapper').fadeOut('1000');
        $('html').removeClass('prevent-scroll');
    }, 1000);
});

/*------------------------------------*\
    REMOVE INVISION PROTOTYPE FOR MOBILE
\*------------------------------------*/

    function removeInVisionPrototypeForMobile() {
        if ($(window).width() < 520) {
            $('.prototype-meandmygolf').remove();
        }
        else {
            $('.prototype-meandmygolf').show();
        }
    }

    // Page load
    removeInVisionPrototypeForMobile();

    // Resize
    var resizeTimer;
    $(window).on('resize', function(e) {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {
        removeInVisionPrototypeForMobile();
      }, 100);
    });

/*------------------------------------*\
    SCROLL TO HYPERLINKS
\*------------------------------------*/

    function enableSmoothScrolling() {
        $('a[href*=\\#]:not([href=\\#])').click(function() {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 1200);
                    return false;
                }
            }
        });
    };
    enableSmoothScrolling();

/*------------------------------------*\
    RESPONSIVE MENU
\*------------------------------------*/

    // Hide on page load
    $('.responsive-menu').hide();

    // Toggle menu
    $('#responsive-menu-button').on('click', function(e) {
        e.preventDefault();
        $('.responsive-menu').toggle();
        $('.responsive-menu').toggleClass('open');
        $('html').toggleClass('prevent-scroll');
        if ($('.responsive-menu').css('opacity') == 0) $('.responsive-menu').css('opacity', 1);
        else $('.responsive-menu').css('opacity', 0);
    });

    // Hamburger button
    $('.hamburger').on('click', function(e) {
        $(this).toggleClass('is-active');
        e.preventDefault();
    });

    // Close on ESC Key
    $(document).keyup(function(e) {
        if (e.keyCode == 27) {
            $('.responsive-menu').hide();
            $('.hamburger').removeClass('is-active');
        }
    });

    // Page load
    // Close responsive menu if resized when open
    if ($(window).width() > 768) {
        $('.responsive-menu').hide();
        $('.hamburger').removeClass('is-active');
    }

    var resizeTimer;
    $(window).on('resize', function(e) {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {

        // Close responsive menu if resized when open
        if ($(window).width() > 768) {
            $('.responsive-menu').hide();
            $('.hamburger').removeClass('is-active');
        }

      }, 100);
    });

    /*------------------------------------*\
        Toggle responsive menu.
    \*------------------------------------*/

    $('.nav-dropdown').hide();

    $('.parent').on('click', function() {
        $(this).next('.nav-dropdown').slideToggle();
        $(this).toggleClass('parent-open');
    });

});
